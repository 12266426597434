<template>
  <div class="landing">
    <section class="landing__showcase">
      <nav class="nav">
        <div class="nav__logo">
          <img src="@/assets/landing/logo@2x.png" alt="sdc21">
        </div>
        <div class="nav__btn-wrap">
          <a href="https://content.leadquizzes.com/lp/DURVfbWv_Q" class="nav__btn" target="_blank">Go to SDC21</a>
        </div>
      </nav>
      <div class="landing__showcase-content">
        <div class="landing__showcase-img">
          <img src="@/assets/landing/showcase.png" alt="">
        </div>
        <div class="landing__showcase-title"><span>#</span>mySDCstack</div>
        <div class="landing__showcase-desc">Get ready for SDC21, held online for the first time ever on October 26.<br />Join your fellow developers by creating your own #mySDCstack!</div>
      </div>
    </section>

    <section class="landing__link">
      <div class="landing__link-title">Craft your SDC stack</div>
        <div class="landing__link-box--a">
          <div class="landing__link-desc">#mySDCstack gives you a chance to see the faces and personalities of<br />SDC fans and attendees like yourself.</div>
          <router-link to="/camera" class="landing__link-btn">Create <span>#</span>mySDCstack <img src="@/assets/landing/icon-bold-action-outlink@2x.png" alt="camera-link" /></router-link>
        </div>
        <div class="landing__link-box--b">
          <div class="landing__link-desc">Or find out your SDC developer type before you make your stack!</div>
          <a href="https://content.leadquizzes.com/lp/DURVfbWv_Q" class="landing__link-btn" target='_blank'>Take the quiz <img src="@/assets/landing/icon-bold-action-outlink@2x.png" alt="camera-link" /></a>
        </div>
    </section>

    <section class="landing__gallery">
      <div class="landing__gallery-title"><span>#</span>mySDCstack Gallery</div>
      <div class="landing__gallery-items">
        <StackItem v-for="(stack, idx) in data.stacksList" :key="idx" :stack="stack"></StackItem>
      </div>
      <div class="landing__gallery__pagination">
        <button :disabled="data.pageCount === 1" class="landing__gallery-btn--back" @click="methods.loadPrev()">
          <img v-if="data.pageCount === 1" src="@/assets/landing/indicator-common-2-arrow-contained-a-left-dark-bg-disabled@2x.png" alt="back" />
          <img v-else src="@/assets/landing/indicator-common-2-arrow-contained-b-right-dark-bg-hover@2x.png" alt="back" :style="{ 'transform': 'rotate(180deg)' }" />
        </button>
        <div class="landing__gallery-btn--count">
          {{data.pageCount}}/{{data.totalPages}}
        </div>
        <button :disabled="data.pageCount === data.totalPages" class="landing__gallery-btn--next" @click="methods.loadNext()">
          <img v-if="data.pageCount === data.totalPages" src="@/assets/landing/indicator-common-2-arrow-contained-a-left-dark-bg-disabled@2x.png" alt="next" :style="{ 'transform': 'rotate(180deg)'}" />
          <img v-else src="@/assets/landing/indicator-common-2-arrow-contained-b-right-dark-bg-hover@2x.png" alt="next" />
        </button>
      </div>
    </section>

    <section class="landing__info">
      <div class="landing__info-title"><span>#</span>mySDCstack Giveaway</div>

      <div class="landing__info-box">
        <div class="landing__info-title--sub">[ Period ]</div>
        <div class="landing__info-desc">September 27-October 26, 2021</div>
      </div>

      <div class="landing__info-box">
        <div class="landing__info-title--sub">[How to participate]</div>
        <div class="landing__info-desc">1. Click the “Create #mySDCstack” button below</div>
        <div class="landing__info-desc">2. Flex your creative talents and use AR stickers to create your own SDC stack
          <div class="landing__info-desc--sub">*Wondering what your SDC Developer type is? Take the quiz before making your SDC stack!</div>
        </div>
        <div class="landing__info-desc">3. Agree to the terms and share your SDC stack with the required hashtags on social media<br /> 
          (Twitter, Instagram, Facebook or LinkedIn)<br />
           Required hashtags: <strong>#mySDCstack #SDC21 #Samsung</strong> + add your developer type: <br />#TheWiseWizard, #TheMightyMaster, #TheValiantChampion, #TheRadicalRebel, #TheMadScientist</div>
        <div class="landing__info-desc">
             4. For a better chance at winning, log in to the SDC21 website and collect the #mySDCstack<br /> badge in the Badge Collection event
          <div class="landing__info-desc--sub">*If you participate, your personalized SDC stack will be shared via the gallery on this page and you will be entered for a chance to win one of the prizes below.<br />
            *A total of 25 people will be selected and contacted individually through DM to their social media account.</div>
        </div>
      </div>

      <div class="landing__info-box">
        <div class="landing__info-title--sub">[Don't need a prize?]</div>
        <div class="landing__info-desc">Even if you don't participate, you can download your SDC stack and/or share it to social media.</div>
      </div>

      <div class="landing__info-box">
        <div class="landing__info-title--sub">[Prize]</div>
        <div class="landing__info__prize">
          <div v-for="({asset, alt, title, color, cssClass}, index) in data.prizes" class="landing__info__prize-item" :key="index">
            <div :class="['landing__info__prize-img', cssClass]">
              <svg class="landing__info__prize-bg" :fill="color" viewBox="0 0 493 493" xmlns="http://www.w3.org/2000/svg">
                <g>
                <path d="M488.5,173c-6.1-45.8-22.3-87.3-50.8-117.7h0C407.3,26.8,365.8,10.6,320,4.5c-43.7-5.8-73.5-4.4-73.5-4.4
                S216.7-1.3,173,4.5c-45.8,6.1-87.3,22.3-117.6,50.8C26.8,85.7,10.6,127.2,4.5,173c-5.8,43.7-4.4,73.5-4.4,73.5s-1.4,29.8,4.4,73.5
                c6.1,45.8,22.3,87.3,50.8,117.7c30.4,28.6,71.9,44.8,117.6,50.9c43.7,5.8,73.5,4.4,73.5,4.4s29.8,1.4,73.5-4.4
                c45.8-6.1,87.3-22.3,117.6-50.9h0c28.6-30.4,44.8-71.9,50.8-117.7c5.8-43.7,4.4-73.5,4.4-73.5S494.3,216.7,488.5,173"/>
                </g>
              </svg>
              <img :src="asset" :alt="alt">
            </div>
            <div class="landing__info__prize-item--desc">
                {{title}}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="landing__footer">
      <div class="landing__footer-title"><strong>[Event Prize Terms &amp; Conditions]</strong></div>
      <div class="landing__footer-desc">-No purchase is necessary to enter or win.</div>
      <div class="landing__footer-desc">-#mySDCstack campaign and related events are subject to change and may change without prior notice.</div>
      <div class="landing__footer-desc">-Winners will be chosen and notified.</div>
      <div class="landing__footer-desc">-The prizes will be awarded at random and are nontransferable. Winners cannot choose the color, design, model or type of the prize they won.</div>
      <div class="landing__footer-desc">-No substitution of prize or transfer/assignment of prize to others or request for the cash equivalent by Winner is permitted.</div>
      <div class="landing__footer-desc">-Acceptance of prize constitutes permission for Samsung to use Winner’s name, likeness, and entry for purposes of advertising related to SDC21 without further compensation unless prohibited by law.</div>
      <div class="landing__footer-desc">-Acceptance of prize constitutes permission for Samsung to collect the Winner’s personal data needed to send prize to Winner. </div>
      <div class="landing__footer-desc">-Winner reserves the right to refuse to provide personal data in which case Winner will lose Winner status and accept that they will not be getting a prize from Samsung.</div>
      <div class="landing__footer-desc">-If the personal data provided by Winner is not accurate, Samsung makes no warranty that the prize will be delivered to Winner.</div>
      <div class="landing__footer-desc">-Prizes will be sent out to Winners after SDC21 and the #mySDCstack campaign and related events are over.</div>
      <div class="landing__footer-desc">-Samsung shall not be liable to Winner or any other party for any claim, loss or damage, direct or indirect, damages to the prize that may arise during delivery and/or during customs procedures.</div>
      <div class="landing__footer-desc">-Samsung shall not be liable to Winner or any other party for any claim, loss or damage, direct or indirect, including, without limitation, compensatory, consequential, incidental, indirect, special, examplary, or punitive damages, regardless of the form of action or basis of any claim.</div>
      <div class="landing__footer-desc">-Samsung reserves the right to correct any errors, inaccuracies or omissions, and to change, modify or update information related to #mySDCstack campaign and to these terms.</div>
    </section>

    <ScrollBtn />
  </div>
</template>

<script>
import { onMounted, onUnmounted, reactive, watchEffect, computed, ref } from 'vue'
import * as $ from 'fxdom';
import * as _ from 'fxjs';
import axios from 'axios';
import StacksData from '@/store/data-stacks.js';
import StackItem from '@/components/StackItem.vue';
import ScrollBtn from '@/components/ScrollBtn.vue';

import smartMonitor from '@/assets/landing/32-m-7-4-k-uhd-smart-monitor-1-copy@2x.png';
import galaxyWatch from '@/assets/landing/galaxy-watch-4-1-copy@2x.png';
import smartTag from '@/assets/landing/galaxy-smart-tag-2-pack-copy@2x.png';
import galaxyBuds from '@/assets/landing/galaxy-buds-2-copy@2x.png';
import portableSSD from '@/assets/landing/portable-ssd-t-7-touch-2-tb-2-copy@2x.png';

export default {
  name: 'landing-test',
  components: { 
    StackItem,
    ScrollBtn
  },
  props: {      
  },
  setup() {    
    const data = reactive({
      stacksList: null,
      testList: null,
      limit: 0,
      pageCount: 1,
      totalPages: 0,
      windowWidth: 0,
      setHtml: 'desktop',
      prizes: [
        {
          asset: smartMonitor,
          alt: "32” M7 4K UHD Smart Monitor",
          title: "32” M7 4K UHD Smart Monitor (5)",
          color: '#0077c8',
          cssClass: 'landing__info__prize-img--landscape'
        },
        {
          asset: galaxyWatch,
          alt: "Galaxy Watch4",
          title: "Galaxy Watch4 (5)",
          color: "#00b3e3",
          cssClass: 'landing__info__prize-img--portrait'

        },
        {
          asset: smartTag,
          alt: "Galaxy SmartTag 2-pack",
          title: "Galaxy SmartTag 2-pack (5)",
          color: "#97d653",
          cssClass: 'landing__info__prize-img--landscape'
        },
        {
          asset: galaxyBuds,
          alt: "Galaxy Buds2",
          title: "Galaxy Buds2 (5)",
          color: "#8093dc",
          cssClass: 'landing__info__prize-img--portrait'
        },
        {
          asset: portableSSD,
          alt: "Portable SSD T7 Touch 2TB",
          title: "Portable SSD T7 Touch 2TB (5)",
          color: "#ffb546",
          cssClass: 'landing__info__prize-img--landscape'
        },
      ]
    })

    const fetchData = async () => {
      const res = await axios.get(`https://sdc21.1024corp.com/users/${data.limit}/${data.pageCount}`);
      // const res = await axios.get(`https://localhost:3000/users/${data.limit}/${data.pageCount}`);
      data.testList = res.data.items;
      data.totalPages = res.data.meta.totalPages;

      // console.log('first List 🚀🚀🚀', data.testList);
      // console.log('first totalPages 🚀🚀🚀', data.totalPages);
    }

    const methods = {
      loadPrev: async () => {
        data.pageCount -= 1;
        fetchData();
      },
      loadNext: async () => {
        data.pageCount += 1;
        fetchData();
      },
      setHtml: () => {
        // data.windowWidth = document.documentElement.clientWidth; 
      },
      setResize: () => {
        data.windowWidth = document.documentElement.clientWidth;   

        if (data.windowWidth <= 600 && data.limit !== 8) {
          data.limit = 8; 
          console.log('mobile limit, pageCount 📲📲📲', data.limit, data.pageCount);
          fetchData();
        } else if (data.windowWidth > 600 && data.limit !== 12) {
          data.limit = 12;
          console.log('desktop limit, pageCount 💻💻💻', data.limit, data.pageCount);
          fetchData();
        }
      },
      init: () => {
        data.windowWidth = document.documentElement.clientWidth;
        
        if (data.windowWidth <= 600 && data.limit !== 8) {
          data.limit = 8; 
          console.log('mobile limit, pageCount 📲📲📲', data.limit, data.pageCount);
          fetchData();
        } else if (data.windowWidth > 600 && data.limit !== 12) {
          data.limit = 12;
          console.log('desktop limit, pageCount 💻💻💻', data.limit, data.pageCount);
          fetchData();
        }
      }
    } 

    onMounted(async () => {
      // methods.setLimit();
      methods.init();
      console.log('first data limit, pageCount 🚀🚀🚀', data.limit, data.pageCount)

      // const res = await axios.get(`https://sdc21.1024corp.com/users/${data.limit}/${data.pageCount}`)
      // const res = await axios.get(`https://cors.dfy.works/sdc21.1024corp.com/users/${data.limit}/${data.pageCount}`)
      // data.testList = res.data.items;

      // console.log('meta', data.testList.meta);
      // data.totalPages = res.data.meta.totalPages;
      
      // 임시 더미 데이터
      data.stacksList = StacksData;
      window.addEventListener("resize", methods.setResize)
    })

    onUnmounted(() => {
      window.removeEventListener("resize", methods.setResize)
    })

    return {
      data,
      methods,
    }
  }
}
</script>

<style scoped lang="scss" src="@/css/landing.scss"></style>
