<template>
  <div class="stack-item__wrapper">
    <div ref="self" class="stack-item">
      <div class="stack-item__header">
        <img src="@/assets/sdc-logo.webp" alt="SDC21 Oct.26">
      </div>
      <div class="stack-item__body">
        <img class="stack-item__bg" v-if="stack.imageUrl" :src="stack.imageUrl" alt="stack image">

        <div class="stack-item__body-ihr">
          <div class="stack-item__sticker" v-for="(info, idx) in data.info" :key="`${info.stickerId}-${idx}`">
            <img v-if="!info.isAnimation" :src="require(`@/assets/stickers/${info.stickerId.replace(/(\d)(0)(\d)/, '$1$3').replace(/(\d)(\d+)/, '$1-$2')}.svg`)" alt="sticker">
            <AnimateSvg v-else :file-name="info.stickerId.replace(/(\d)(0)(\d)/, '$1$3').replace(/(\d)(\d+)/, '$1-$2')" />
          </div>
        </div>

        <div class="stack-item__footer">
          <div>
            <div class="stack-item__footer-name">
              {{stack.name || "SDC Developer"}}
            </div>
            <div class="stack-item__footer-desc">
              {{stack.message}}
            </div>
          </div>

          <div class="stack-item__footer-qr">
            <img src="@/assets/qr-temp.png" alt="qr code">
          </div>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, reactive, ref, useStore, onMounted, onUnmounted } from "@/helper/vue.js";
import AnimateSvg from '@/components/AnimateSvg.vue'
import { nextTick } from '@vue/runtime-core';

export default {
  name: "StackItem",
  props: {
    stack: Object
  },
  components: {
    AnimateSvg,
  },
  setup(props) {
    const self = ref('');
    const data = reactive({
      info: computed(() => JSON.parse(props.stack.stickerInfo))
    })
    const methods = {
      setStickers: () => {
        const stickers = self.value.querySelectorAll('.stack-item__sticker');
        stickers.forEach((el, index) => {
          const info = data.info[index];
          if (!info) {
            return
          }
          el.style.transform = `scale(${info.scaleX}, ${info.scaleY}) rotate(${info.angle}deg)`;
          el.style.top = `${info.top / 560 * 100}%`;
          el.style.left = `${info.left / 560 * 100}%`;
          el.style.width = `${info.width / 560 * 100}%`;
          el.style.height = `${info.height / 560 * 100}%`;
        });
      }
    }

    watch(
      () => data.info,
      () => {
        nextTick(methods.setStickers)
      }
    )

    onMounted(() => {
      methods.setStickers();
    })

    onUnmounted(() => {
    })

    return {
      data,
      methods,
      self,
    }
  }
}
</script>

<style lang="scss">
.stack-item {
  position: relative;
  width: 100%;
  padding-top: 100%;

  &__wrapper {
    width: calc(25% - 24rem);
    margin: 12rem;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
      width: 159rem;
      margin: 4.5rem;
    }
  }

  &__header {
    position: absolute;
    top: calc(100% * (23 / 560));
    right: calc(100% * (420 / 560) * (22 / 420));
    width: calc(100% * (103 / 560));
    height: calc(100% * (420 / 560) * (61 / 420));
    z-index: 10;
  }

  &__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &-ihr {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding-top: 100%;
    }

    img {
      width: 100%;
      /* height: 226.5em; */
    }
  }

  &__bg {
    border-top-left-radius: 8rem;
    border-top-right-radius: 8rem;

    @media only screen and (max-width: 600px) {
      border-top-left-radius: 4rem;
      border-top-right-radius: 4rem;
    }
  }

  &__sticker {
    position: absolute;
    transform-origin: top left;
    img {
      display: block;
      width: 100%;
    }
  }

  &__footer {
    position: relative;
    z-index: 10;
    padding: 12rem 12rem 12rem 13rem;
    width: 302rem;
    height: 76rem;
    background-color: #1428a0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-left-radius: 8rem;
    border-bottom-right-radius: 8rem;

    @media only screen and (max-width: 600px) {
      width: 159rem;
      height: 40rem;
      padding: 12rem 7rem;
      border-bottom-left-radius: 4rem;
      border-bottom-right-radius: 4rem;
    }

    &-name {
      margin-top: 6rem;
      text-align: left;
      font-family: 'SamsungSharpSans';
      font-size: 20em;
      font-weight: bold;
      line-height: 1.4;
      color: #fff;

      @media only screen and (max-width: 768px) {
        font-size: 10em;
      }
    }

    &-desc {
      text-align: left;
      font-family: 'SamsungOne';
      font-size: 16em;
      font-weight: 400;
      line-height: 1.5;
      color: #fff;
      white-space:nowrap;

      @media only screen and (max-width: 768px) {
        // smallest font 10px
        transform: scale(0.8) translateX(-15rem) translateY(-3rem);
        width: 115rem;
        font-size: 8em;
        /* line-height: 2; */
        color: #fff;
      }
    }

    &-qr {
      width: 52rem;
      height: 52rem;

      img {
        width: 100%;
      }

      @media only screen and (max-width: 768px) {
        width: 26rem;
        height: 26rem;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
