<template>
  <div class="scroll-btn" :class="{'scroll-btn--show': data.showScroll}">
    <button @click="methods.scrollToTop"><img src="@/assets/landing/indicator-common-2-arrow-contained-b-right-dark-bg-hover@2x.png" alt="scroll-to-top"></button>
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { reactive, ref, useStore, onMounted, watchEffect, onUnmounted } from "@/helper/vue.js";

export default {
  name: 'scroll-btn',
  setup() {
    const data = reactive({
      showScroll: false
    })

    const methods = {
      scrollToTop: () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      checkScrollTop: () => {
        if (!data.showScroll && window.pageYOffset > 400) {
          data.showScroll = true;
        } else if (data.showScroll && window.pageYOffset <= 400) {
          data.showScroll = false;
          // if ($.hasClass("scroll-btn--show",  $.qs(".scroll-btn"))) {
          //   $.removeClass("scroll-btn--show",  $.qs(".scroll-btn"));
          // }
        }
      }
    }

    watchEffect((onInvalidate) => {
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', methods.checkScrollTop);
      }
      onInvalidate(() => window.removeEventListener('scroll', methods.checkScrollTop));
    })

    // onMounted(() => {
    //   if (typeof window !== 'undefined') {
    //     window.addEventListener('scroll', methods.checkScrollTop);
    //   }
    // })

    // onUnmounted(() => {
    //   window.removeEventListener('scroll', methods.checkScrollTop)
    // })

    return {
      data,
      methods
    }
  }
}
</script>

<style lang="scss">
.scroll-btn {
  opacity: 0;
  transition: all 400ms ease-in-out;
  position: fixed;
  bottom: 24px;
  right: 16px;
  z-index: 20;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
    transform: rotate(-90deg);
  }

  &--show {
    opacity: 1;
  }
}
</style>