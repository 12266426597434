export default [
  {
    id: 1,
    name: "Andrew Herg",
    message: "Devs don't quit.",
    imgSrc: 'https://picsum.photos/500/375?random=1',
    stickerInfo: [
      {
        angle: 24.46240268682455,
        height: 24,
        id: "0-3",
        isAnimate: true,
        left: 24.55446039171265,
        scaleX: 1.2338992974238878,
        scaleY: 1.2338992974238878,
        top: 6.3569325485110895,
        width: 16.666666666666668    
      }, 
      {
        angle: 0,
        height: 21.666666666666668,
        id: "0-0",
        isAnimate: false,
        left: 18.57142857142857,
        scaleX: 1,
        scaleY: 1,
        top: 64.82142857142857,
        width: 22.666666666666668,
      },
      {
        angle: 0,
        height: 5.733330923032305,
        id: "1-0",
        isAnimate: false,
        left: 70.3055942381922,
        scaleX: 4.433727619262866,
        scaleY: 4.433727619262866,
        top: -0.5700326702203332,
        width: 6.467313534665354,
      }, 
      {
        angle: 0,
        height: 5.8822969656942465,
        id: "0-1",
        isAnimate: false,
        left: 32.6510573511635,
        scaleX: 3.6252332451158793,
        scaleY: 3.6252332451158793,
        top: 23.241799791693648,
        width: 6.669226843274887,
      }
    ]
  },
  {
    id: 2,
    name: "Junghun Kim",
    message: "Just call me the bugcatcher",
    imgSrc: 'https://picsum.photos/500/375?random=2',
    stickerInfo: [
      {
        angle: 44.51502944135692,
        height: 24,
        id: "0-3",
        left: 59.41240501057475,
        scaleX: 1.3030573810296506,
        scaleY: 1.3030573810296506,
        top: 54.13178098831186,
        width: 16.666666666666664,
        isAnimate: true,
      },
      {
        angle: 39.132126586874776,
        height: 5.882296965694246,
        id: "1-1",
        left: 79.65114243021114,
        scaleX: 2.801778228546363,
        scaleY: 2.801778228546363,
        top: -1.8904176619540547,
        width: 6.669226843274887,
      },
      {
        angle: 0,
        height: 28.88888888888889,
        id: "0-0",
        isAnimate: false,
        left: 21.707157973605323,
        scaleX: 3.4465534465534464,
        scaleY: 3.4465534465534464,
        top: 0.1327345899714424,
        width: 22.666666666666668
      },
    ]
  },
  {
    id: 3,
    name: "Pedro Sanches",
    message: "Devs don't quit.",
    imgSrc: 'https://picsum.photos/500/375?random=3',
    stickerInfo: [
      {
        angle: 0,
        height: 5.733330923032305,
        id: "2-0",
        left: 7.062737095335028,
        scaleX: 2.314034986662781,
        scaleY: 2.314034986662781,
        top: 33.69342187982266,
        width: 6.467313534665353
      }, 
      {
        angle: 327.4992801872895,
        height: 5.882296965694246,
        id: "1-2",
        left: 51.20743120891544,
        scaleX: 3.0156726466141115,
        scaleY: 3.0156726466141115,
        top: 70.48071931542714,
        width: 6.669226843274887
      },
      {
        angle: 54.34317256737194,
        height: 5.882296965694246,
        id: "0-1",
        left: 77.3293668851618,
        scaleX: 1.6274763449596799,
        scaleY: 1.6274763449596799,
        top: 10.805000617348668,
        width: 6.669226843274887
      },
      {
        angle: 0,
        height: 5.733330923032305,
        id: "1-2",
        left: 7.062737095335028,
        scaleX: 2.314034986662781,
        scaleY: 2.314034986662781,
        top: 33.69342187982266,
        width: 6.467313534665353
      }
    ]
  },
  {
    id: 4,
    name: "Amelia",
    message: "My first words? hello World!",
    imgSrc: 'https://picsum.photos/500/375?random=4',
    stickerInfo: [
      {
        angle: 183.92784615868095,
        height: 5.882296965694246,
        id: "0-2",
        left: 54.90682892923707,
        scaleX: 3.0156726466141115,
        scaleY: 3.0156726466141115,
        top: 33.98515736505012,
        width: 6.669226843274887,
      }, 
      {
        angle: 39.17817807439152,
        height: 5.882296965694246,
        id: "2-1",
        left: 70.3955004755127,
        scaleX: 4.373159709597088,
        scaleY: 4.373159709597088,
        top: 52.71717014737548,
        width: 6.669226843274887,
      },
      {
        angle: 55.8425184284282,
        height: 5.733330923032305,
        id: "2-0",
        left: 49.35257037926246,
        scaleX: 2.438381090097735,
        scaleY: 2.438381090097735,
        top: 8.521595003043862,
        width: 6.467313534665353,
      }
    ]
  },
  {
    id: 5,
    name: "Suji Jang",
    message: "My first words? hello World!",
    imgSrc: 'https://picsum.photos/500/375?random=5',
    stickerInfo: [
      {
        angle: 183.92784615868095,
        height: 5.882296965694246,
        id: "1-2",
        left: 47.573495595903736,
        scaleX: 3.0156726466141115,
        scaleY: 3.0156726466141115,
        top: 75.58515736505012,
        width: 6.669226843274887,
      }, 
    ]
  },
  {
    id: 6,
    name: "Hyongsub Han",
    message: "My first words? hello World!",
    imgSrc: 'https://picsum.photos/500/375?random=6',
    stickerInfo: [
      {
        angle: 0,
        height: 5.733330923032305,
        id: "2-1",
        left: 26.129403762001715,
        scaleX: 4.312827864304806,
        scaleY: 4.312827864304806,
        top: 30.549014948827292,
        width: 6.467313534665353,
      }, 
      {
        angle: 318.60856833758965,
        height: 5.882296965694246,
        id: "2-2",
        left: 54.13901845425431,
        scaleX: 4.373159709597088,
        scaleY: 4.373159709597088,
        top: 53.12023269005228,
        width: 6.669226843274887,
      },
      {
        angle: 55.8425184284282,
        height: 5.733330923032305,
        id: "1-2",
        left: 44.731997101550036,
        scaleX: 3.4568289054126526,
        scaleY: 3.4568289054126526,
        top: 21.18584383610984,
        width: 6.467313534665353,
      },
      {
        angle: 183.92784615868095,
        height: 5.882296965694246,
        id: "0-2",
        left: 32.2401622625704,
        scaleX: 3.0156726466141115,
        scaleY: 3.0156726466141115,
        top: 87.58515736505012,
        width: 6.669226843274887,
      },
    ]
  },
  {
    id: 7,
    name: "Haper Mia",
    message: "Devs don't quit.",
    imgSrc: 'https://picsum.photos/500/375?random=7',
    stickerInfo: [
      {
        angle: 318.60856833758965,
        height: 5.882296965694246,
        id: "2-2",
        left: 61.20568512092097,
        scaleX: 4.373159709597088,
        scaleY: 4.373159709597088,
        top: 32.72023269005229,
        width: 6.669226843274887,
      },
      {
        angle: 206.28543532330673,
        height: 5.733330923032305,
        id: "0-2",
        left: 77.75019958729109,
        scaleX: 3.4568289054126526,
        scaleY: 3.4568289054126526,
        top: 83.27340652346268,
        width: 6.467313534665353
      },
      {
        angle: 231.23847130650375,
        height: 5.882296965694246,
        id: "1-1",
        left: 73.11749746643638,
        scaleX: 3.0156726466141115,
        scaleY: 3.0156726466141115,
        top: 57.34604699793821,
        width: 6.669226843274887
      },
      {
        angle: 135.60708260671487,
        height: 5.882296965694246,
        id: "2-1",
        left: 58.79464497761102,
        scaleX: 4.373159709597088,
        scaleY: 4.373159709597088,
        top: 26.992153342184764,
        width: 6.669226843274887
      },
      {
        angle: 206.28543532330673,
        height: 5.733330923032305,
        id: "0-1",
        left: 45.54569079418719,
        scaleX: 5.325722437959638,
        scaleY: 5.325722437959638,
        top: 95.83513853224065,
        width: 6.467313534665353
      }
    ]
  },
  {
    id: 8,
    name: "Zhuhung Lee",
    message: "My first words? hello World!",
    imgSrc: 'https://picsum.photos/500/375?random=8',
    stickerInfo: [
      {
        angle: 324.6399263194868,
        height: 5.882296965694246,
        id: "1-2",
        left: 61.78016080337393,
        scaleX: 3.0156726466141115,
        scaleY: 3.0156726466141115,
        top: 71.84559302528454,
        width: 6.669226843274887
      },
      {
        angle: 73.11383260150343,
        height: 5.882296965694246,
        id: "2-2",
        left: 26.6363739540305,
        scaleX: 4.373159709597088,
        scaleY: 4.373159709597088,
        top: 13.126324420952008,
        width: 6.669226843274887,
      }
    ]
  },
  {
    id: 9,
    name: "Saeme Oh",
    message: "Real devs count from 0",
    imgSrc: 'https://picsum.photos/500/375?random=1',
    stickerInfo: [
      {
        angle: 0,
        height: 5.733330923032305,
        id: "0-1",
        left: 0.6627370953350441,
        scaleX: 5.798912705627344,
        scaleY: 5.798912705627344,
        top: 1.2156816154939563,
        width: 6.467313534665354
      }, 
      {
        angle: 0,
        height: 6.666666666666666,
        id: "2-2",
        left: 60.65292814960628,
        scaleX: 2.5023375984251963,
        scaleY: 2.5023375984251963,
        top: 10.145669291338574,
        width: 6.666666666666666,
      },
      {
        angle: 0,
        height: 5.882296965694246,
        id: "2-1",
        left: 1.4829488105095798,
        scaleX: 1,
        scaleY: 1,
        top: 45.26091013816963,
        width: 6.669226843274887,
      }
    ]
  },
  {
    id: 10,
    name: "Junhyung Kim",
    message: "just call me the bugcatcher",
    imgSrc: 'https://picsum.photos/500/375?random=2',
    stickerInfo: [
      {
        angle: 44.51502944135692,
        height: 24,
        id: "0-3",
        left: 59.41240501057475,
        scaleX: 1.3030573810296506,
        scaleY: 1.3030573810296506,
        top: 54.13178098831186,
        width: 16.666666666666664,
        isAnimate: true,
      },
      {
        angle: 39.132126586874776,
        height: 5.882296965694246,
        id: "1-1",
        left: 79.65114243021114,
        scaleX: 2.801778228546363,
        scaleY: 2.801778228546363,
        top: -1.8904176619540547,
        width: 6.669226843274887,
      },
      // {
      //   angle: 38.77793029633699,
      //   height: 5.8822969656942465,
      //   id: "2-1",
      //   left: 85.45272190224475,
      //   scaleX: 3.14561623435477,
      //   scaleY: 3.14561623435477,
      //   top: -2.6385057404159347,
      //   width: 6.669226843274887        
      // },
    ]
  },
  {
    id: 11,
    name: "Jocelyn Tsaih",
    message: "just call me the bugcatcher",
    imgSrc: 'https://picsum.photos/500/375?random=4',
    stickerInfo: [
      {
        angle: 183.92784615868095,
        height: 5.882296965694246,
        id: "0-2",
        left: 54.90682892923707,
        scaleX: 3.0156726466141115,
        scaleY: 3.0156726466141115,
        top: 33.98515736505012,
        width: 6.669226843274887,
      }, 
      {
        angle: 39.17817807439152,
        height: 5.882296965694246,
        id: "2-1",
        left: 70.3955004755127,
        scaleX: 4.373159709597088,
        scaleY: 4.373159709597088,
        top: 52.71717014737548,
        width: 6.669226843274887,
      },
      {
        angle: 55.8425184284282,
        height: 5.733330923032305,
        id: "2-0",
        left: 49.35257037926246,
        scaleX: 2.438381090097735,
        scaleY: 2.438381090097735,
        top: 8.521595003043862,
        width: 6.467313534665353,
      }
    ]
  },
  {
    id: 12,
    name: "Jocelyn Tsaih",
    message: "My first words? Hello World!",
    imgSrc: 'https://picsum.photos/500/375?random=1',
    stickerInfo: [
      {
        angle: 24.46240268682455,
        height: 24,
        id: "0-3",
        isAnimate: true,
        left: 24.55446039171265,
        scaleX: 1.2338992974238878,
        scaleY: 1.2338992974238878,
        top: 6.3569325485110895,
        width: 16.666666666666668    
      }, 
      {
        angle: 0,
        height: 21.666666666666668,
        id: "0-0",
        isAnimate: false,
        left: 18.57142857142857,
        scaleX: 1,
        scaleY: 1,
        top: 64.82142857142857,
        width: 22.666666666666668,
      },
      {
        angle: 0,
        height: 5.733330923032305,
        id: "1-0",
        isAnimate: false,
        left: 70.3055942381922,
        scaleX: 4.433727619262866,
        scaleY: 4.433727619262866,
        top: -0.5700326702203332,
        width: 6.467313534665354,
      }, 
      {
        angle: 0,
        height: 5.8822969656942465,
        id: "0-1",
        isAnimate: false,
        left: 32.6510573511635,
        scaleX: 3.6252332451158793,
        scaleY: 3.6252332451158793,
        top: 23.241799791693648,
        width: 6.669226843274887,
      }
    ]
  },
  // {
  //   id: 13,
  //   name: "Jocelyn Tsaih",
  //   message: "My first words? Hello World!",
  //   imgSrc: 'https://picsum.photos/500/375?random=1',
  //   stickerInfo: [
  //     {
  //       angle: 24.46240268682455,
  //       height: 24,
  //       id: "0-3",
  //       isAnimate: true,
  //       left: 24.55446039171265,
  //       scaleX: 1.2338992974238878,
  //       scaleY: 1.2338992974238878,
  //       top: 6.3569325485110895,
  //       width: 16.666666666666668    
  //     }, 
  //     {
  //       angle: 0,
  //       height: 21.666666666666668,
  //       id: "0-0",
  //       isAnimate: false,
  //       left: 18.57142857142857,
  //       scaleX: 1,
  //       scaleY: 1,
  //       top: 64.82142857142857,
  //       width: 22.666666666666668,
  //     },
  //     {
  //       angle: 0,
  //       height: 5.733330923032305,
  //       id: "1-0",
  //       isAnimate: false,
  //       left: 70.3055942381922,
  //       scaleX: 4.433727619262866,
  //       scaleY: 4.433727619262866,
  //       top: -0.5700326702203332,
  //       width: 6.467313534665354,
  //     }, 
  //     {
  //       angle: 0,
  //       height: 5.8822969656942465,
  //       id: "0-1",
  //       isAnimate: false,
  //       left: 32.6510573511635,
  //       scaleX: 3.6252332451158793,
  //       scaleY: 3.6252332451158793,
  //       top: 23.241799791693648,
  //       width: 6.669226843274887,
  //     }
  //   ]
  // },
  // {
  //   id: 14,
  //   name: "Jocelyn Tsaih",
  //   message: "My first words? Hello World!",
  //   imgSrc: 'https://picsum.photos/500/375?random=1',
  //   stickerInfo: [
  //     // {
  //     //   angle: 44.51502944135692,
  //     //   height: 24,
  //     //   id: "0-3",
  //     //   left: 59.41240501057475,
  //     //   scaleX: 1.3030573810296506,
  //     //   scaleY: 1.3030573810296506,
  //     //   top: 54.13178098831186,
  //     //   width: 16.666666666666664,
  //     //   isAnimate: true,
  //     // },
  //     // {
  //     //   angle: 39.132126586874776,
  //     //   height: 5.882296965694246,
  //     //   id: "1-1",
  //     //   left: 79.65114243021114,
  //     //   scaleX: 2.801778228546363,
  //     //   scaleY: 2.801778228546363,
  //     //   top: -1.8904176619540547,
  //     //   width: 6.669226843274887,
  //     // },
  //   ]
  // }
]